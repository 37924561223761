
export const ReplacePhoneticSymbols = function(text){
    let temArr = text.split("[")
    let str = ''
    if(temArr.length === 1){
        str = temArr[0]
    }else{
        str = temArr[0] + '<span style="font-weight: lighter;">[' + temArr[1] + '</span>'
    }
    return str
}