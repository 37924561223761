<template slot="action">
    <div class="words-backdrop">
        <div class="words" >
                <el-container class="words0">
                    <!-- 顶栏 -->
                    <el-header class="words-header" height="44px">
                        <h3 class="words-title">生词本</h3>
                        <div class="left">
                            <div class="wordsInput" >
                                <el-input type="text" v-model="input"  auto-complete="off" class="wordsText" prefix-icon="iconfont el-icon-search" />
                            </div>
                            <span class="serchButton" @click="search">Search</span>
                        </div>
                        <i class="el-icon-close right" @click="closeSelf"></i> 
                    </el-header>
                    <!-- 嵌套容器 -->
                    <el-container>
                        <!-- 侧边导航菜单 -->
                        <el-aside width="200px" class="side">
                            <div v-for='(item,index) in allData' :key='index' class="leftcontent" :class="{leftcontentActive: item.name == currentKey }" @click='query(item)'>
                                <div >{{item.name}}({{item.list.length}})</div>


                            </div>
                        </el-aside>
                        <!-- 内容 -->
                        <el-main class="main1">
                            <div class="content">
                                <div class="controll">
                                    <div><el-checkbox v-model="checked" v-show="!this.$store.state.common.menushow" @change="Change1">全选</el-checkbox></div>
                                    <div class="controll">
                                        <div v-show="this.$store.state.common.menushow" class="controllButton" @click="startControl">
                                            <img style="width: 10px;height: 10px" src='../../assets/menu3.png'>
                                            <span> 管理</span>
                                        </div>
                                        <div v-show="!this.$store.state.common.menushow" class="controllButton" @click="deleteData">
                                            <img style="width: 10px;height: 10px" src='../../assets/delete.png'>
                                            <span> 删除</span>
                                        </div>
                                        <div  style="margin-left: 20px" v-show="!this.$store.state.common.menushow" class="controllButton" @click="closeControl">
                                            <span> 取消</span>
                                        </div>
                                    </div>

                                </div>
                                <el-collapse accordion v-model='collapseValue'>
                                    <div v-for='(item,index) in contentData.slice((currentPage-1)*pageSize,(currentPage*pageSize))' :key='index' class="content1">
                                        <el-collapse-item >
                                            <template slot="title">
                                                <div class="title-content">
                                                    <div style="display: flex;align-items: center;width: 80%;">
                                                        <el-checkbox v-model="item.checked" v-show="!$store.state.common.menushow" :class="returnName" @change="Change2"/>
                                                        <AudioPlayer ref="stemAudioPlayer" v-show="Pattern!='测试模式'"
                                                                     v-if="item.englishAudioResource !== null"
                                                                     :theUrl="item.englishAudioResource" @audioEndedOver="PlayerComplete" 
                                                                     :config="AudioPlayerConfig" class="click-play" />
                                                        <span class="englishText">{{item.englishText}}<span class="chineseText" v-if="item.chineseText !='null'">{{item.chineseText}}</span></span>
                                                    </div>
                                                    <span class="date1" style="width: 20%;">{{item.date}}</span>
                                                </div>
                                            </template>

                                            <div class="collapse-item-content">
                                                <div class="left">
                                                    <div class="left1">
                                                        <span style="word-break:break-word">{{item.wordsPhrases}}</span>
                                                        <AudioPlayer ref="stemAudioPlayer"
                                                                    v-if="item.wordsPhrasesResource"
                                                                    :theUrl="item.wordsPhrasesResource"
                                                                    @audioEndedOver="PlayerComplete"
                                                                    :config="AudioPlayerConfig"/>
                                                    </div>
                                                    <div class="left2">
                                                        <span>{{item.chineseLanguage}}</span>
                                                    </div>
                                                    <div class="left3">
                                                        <span style="color: #2d8cf0" @click="gotoUrl(item)">{{item.sectionName}}</span>
                                                    </div>
                                                </div>
                                                <div class="right"  v-if="item.wordsScenePictureResource">
                                                    <img :src="getPic(item.wordsScenePictureResource)" class="wordsimg"
                                                       ></img>
                                                </div>
                                            </div>
                                        </el-collapse-item>
                                    </div>

                                </el-collapse>
                                <div class="noData" v-if="contentData.length == 0" 
                                    style="width: 100%;height: 100%;    
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;">
                                    <img style="width: 100px;height: 100px" src='../../assets/img_nohavedata.png'>
                                    <p style="margin-top:20px">暂无数据信息</p>
                                </div>
                                
                            </div>
                            <el-footer class="main-footer" height="38px">
                                <p>共 <span class="num">{{contentData.length}}</span>条信息</p>
                                <el-pagination layout="prev, pager, next"
                                               :total="contentData.length"
                                               :page-size="pageSize"
                                               :current-page ="currentPage"
                                               @current-change="handleCurrentChange"
                                               class="page">
                                </el-pagination>
                            </el-footer>
                        </el-main>
                    </el-container>
                </el-container>
        </div>
    </div>
</template>

<style  lang="less" scoped>
    .words-backdrop {
        z-index: 600;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,.3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .words {
        background-color: #fff;
        box-shadow: 2px 2px 20px 1px;
        overflow-x:auto;
        overflow-y:auto;
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        width: 1000px;
        height: 600px;
        .words0{
            height: 100%;
            .el-container{
                height: 556px;
                .content{
                    height: 100%;
                    overflow-y: scroll;
                    background-color: #ffffff;
                }
            }
        }
    }
    .words-header {
        background-color: #c7c7c7;
        border-bottom: 1px solid #eee;
        color: #313131;
        justify-content: space-between;
        padding: 15px;
        display: flex;
        height: 10px;
        align-items: center;
        .words-title {
            font-size: 16px;
        }
        i {
            font-size: 12px;
            color: #333B4E;
            font-weight: 600;
        }
        .left{
            display: flex;
            align-items: center;
            height: 30px;
            .wordsInput {
                width: 262px;
                i {
                    font-size: 12px;
                }
            }
            /deep/.el-input__inner{
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border: 0;
                height: 30px;
                width: 262px;
            }

            /deep/ .el-input__icon{
                line-height: normal;
            }
            .serchButton {
                width: 99px;
                height: 30px;
                background-color: #000;
                color: #fff;
                padding: 0px 10px;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                cursor:pointer;
                font-size: 16px;
                text-align: center;
                line-height: 30px;
                &:hover{
                    background-color: #999999;
                }
            }
        }
    }
    .el-main{
        padding: 10px 20px 20px;
    }
    .main1{
        position: relative;
        overflow-x:hidden;
        overflow-y:hidden;
        .main-footer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
            .num{
                color: #00D2CB;
            }
        }
        .content{
            .content1 {
                border-bottom: 1px solid #F0F0F0;
            }
            .title-content{
                display: flex;
                justify-content: space-between;
                flex: 1;
                align-items: center;
                font-size: 16px;
                .Favorites{
                    width: 15px;
                    height: 15px;
                }
                .englishText{
                    display: inline-block;
                    width: 540px;
                    white-space:nowrap;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    text-align: left;
                    .chineseText {
                        display: inline-block;
                        margin-left: 20px;
                    }
                }
                .date1{
                    color: #9c9a9d;
                }
            }
            /deep/.el-collapse-item__header{
                display: flex;
                justify-content: space-between;
            }
            /deep/.el-icon-arrow-right{
                margin-left: 20px;
            }
            /deep/ .el-collapse-item__wrap {
                background: #FBFBFB;
                overflow: auto;
                height: 260px;

                .collapse-item-content {
                    width: 100%;
                    display: flex;
                    padding-top: 20px;
                    font-size: 16px;
                    .left {
                        padding: 0px 27px;
                        flex: 1;
                        text-align: left;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .left2 {
                            margin-top: 20px;
                        }
                        .left3 {
                            margin-top: 20px;
                            
                            span {
                                cursor: pointer;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }

                    .right {
                        flex: 1;
                        img {
                            width: 300px;
                            height: 200px;
                        }
                    }
                }
            }
            .controll{
                display: flex;
                justify-content: space-between;
                .controllButton {
                    display: flex;
                    align-items: center;
                    background-color: #eeeeee;
                    padding: 3px 8px;
                    justify-content: space-around;
                    width: 60px;
                    height: 20px;
                    border-radius: 5px;
                    cursor: pointer;
                    &:hover{
                        background-color: #999999;
                    }
                }
            }

        }
        .Favorites {
            .unActive{
                display: inline-block;
                width: 0.42rem;
                height: 0.42rem;
                background-size: cover;
            }
        }
        .controll {
            height: 30px;
            text-align: right;
        }
    }
    .btn-close, .btn-confirm {
        border-radius: 8px;
        margin-left:16px;
        width:56px;
        height: 36px;
        border:none;
        cursor: pointer;
    }
    .btn-close {
        color: #313131;
        background-color:transparent;
    }
    .btn-confirm {
        color: #fff;
        background-color: #2d8cf0;
    }

    .el-footer {
        background-color: #ffffff;
        color: black;
        text-align: center;
        border:2px solid #eeeeee;
        .page {

        }
    }

    .content {
        background-color: #909399;
        color: black;
        text-align: center;
    }

    .side {
        background-color: #ffffff;
        border:2px solid #eeeeee;
        padding-left: 5px;
        .leftcontent {
            box-sizing: border-box;
            margin: 5px 0px;
            width: 100%;
            height: 28px;
            background-color: #eeeeee;
            padding-left: 3px;
            line-height: 28px;
            cursor: pointer;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
            text-align: left;
            font-size: 18px;

            &:hover{
                background-color: #999999;
            }
        }
        .leftcontentActive{
            background-color: #999999;
        }
        overflow-y: scroll;
    }
    .el-icon-close{
        cursor: pointer;
        padding: 3px  4px 3px 3px;
        width: 10px;
        height: 10px;
    }
    .el-icon-close:hover{
        background-color: #fff;

        border-radius: 50%;
    }

    .side1 {
        overflow-y: auto;
        height: 300px
    }

    .wordsimg{
        width: 300px;
        height: 200px;
    }

    .words2{
        display: flex;
        align-items: center;
        font-size: 15px;
        height: 100px;
    }

    .content3 {
        display: flex;
        align-items: center;
        font-size: 15px;
        height: 100px;
        overflow-y: auto;
    }
</style>

<script>
    export default {
        name: 'words',
        props: {
        },
        data() {
            return {
                checked: false,
                form: {
                    words: ''
                },
                input:"",
                sideData: [],
                midData: [],
                pageData: [],
                AudioPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[1],
                },
                pageSize: 10,
                currentPage:1,
                currentKey:"",
                allData:[],
                contentData:[],

                collapseValue:""
            }
        },
        created(){
            this.search()
        },
        mounted () {
            // 注册事件
            this.bus.$on('updateData', (content)=>{
                this.search()
            });
        },
        // 注册的总线事件要在组件销毁时卸载，否则会多次挂载，造成触发一次但多个响应的情况
        beforeDestroy () {
            this.bus.$off('updateData', (content)=>{
                console.log(content);
            });
        },
        
        methods: {
            Goto(item){
                let list = this.menulist;
                console.log(list)
                let layer = item.ceng.split(',');
                layer = layer.map((el)=>{
                    el = el.split('.');
                    return el;
                })
                if(item.allSectionModel == 3){ // 测试模式
                    let starttime = new Date();
                    starttime = window.formatDateTime(starttime);
                    sessionStorage.setItem('starttime',starttime);
                }

                var id = item.id
                console.log(id)
                let ceng = item.ceng
                console.log(item.ceng)

                let id2 = list[layer[0][1]].id
                console.log(id2)

                let pid = item.pid
                console.log(pid)

                sessionStorage.setItem('checkid',item.id);
                sessionStorage.setItem('targetceng',item.ceng);

                this.$store.commit('setitem',{'name':'checkid','item':item.id})
                this.$store.commit('setitem',{'name':'outerId','item':list[layer[0][1]].id});
                this.$store.commit('setitem',{'name':'dataLayer','item':item.ceng})
                this.$router.push('/home/describe?pid=' + item.pid);
            },
            searchData(data, key){
                if(data.childList && data.childList.length > 0 ) {
                    for (let j=0; j < data.childList.length; j++) {
                        this.searchData(data.childList[j], key)
                    }
                }
                if(data.id === key){
                    this.$store.commit('setitem',{'name':'searchActive','item':false});
                    this.Goto(data)
                }
            },
            gotoUrl(item) {
                let menulist = JSON.parse( sessionStorage.getItem("menulist") )
                for (let j=0; j < menulist.length; j++) {
                    this.searchData(menulist[j], item.sectionId)
                }
            },
            Change1(val) {
                console.log(val,'val');
                let arr = this.contentData.slice((this.currentPage-1)*this.pageSize,(this.currentPage*this.pageSize))
                    let flag = true
                    arr.map(el=>{
                        el.checked = val
                    })
            },
            Change2(val) {
                    let arr = this.contentData.slice((this.currentPage-1)*this.pageSize,(this.currentPage*this.pageSize))
                    let flag = true
                    arr.map(el=>{
                        if(!el.checked) flag = false
                    })
                    this.checked = flag
                
            },
            getPic(url) {
                let url1 = this.$store.state.common.AudioUrlPublic + '/Images/' + url
                console.log(url1)
                return url1
            },
            query(item) {
                this.currentPage = 1
                this.currentKey = item.name
                console.log();
                this.contentData = item.list
            },
            handleSearch(searchValue) {
                this.isShowHotSearch = false
                console.log(searchValue, 'searchValue');
                this.input = searchValue
                this.search()
            },
            handleCurrentChange(val) {
                this.collapseValue = ''
                this.currentPage = val
            },
            returnName(index) {
                return 'check' + index;
            },
            closeSelf() {
                this.$store.commit('setitem',{'name':'wordsActive','item':false});
            },
            change(e) {
              this.$forceUpdate()
            },
            startControl() {
                if (this.contentData.length === 0) {
                    return
                }
                this.$store.commit('setitem',{'name':'menushow','item':false});
            },
            async deleteData () {
                let ids = ''
                for (let i = 0; i < this.contentData.length; i++) {
                    if (this.contentData[i].checked === true) {
                        ids += this.contentData[i].id
                        ids += ','
                    }
                }
                ids = ids.substr(0, ids.length - 1)

                console.log('ids',ids)
                this.checked = false
                this.$store.commit('setitem',{'name':'menushow','item':true});

                let data = await this.AjaxApi.deleteData(ids)
                this.currentPage = 1
                if (data === false) {
                    this.$message.error('删除失败!');
                    return
                } else {
                    this.$emit("childMsg","hello world!");
                }

                this.search()
            },
            closeControl() {
                this.$store.commit('setitem',{'name':'menushow','item':true});
                for (let i = 0; i < this.pageData.length; i++) {
                    this.pageData[i].checked = false
                }
                this.checked = false
            },
            async search() {
                this.collapseValue = ''
                let layer = sessionStorage.getItem('targetceng').split(',');
                layer = layer.map(el => {
                    el = el.split('.');
                    return el;
                })

                let courceId = this.$parent.menulist[layer[0][1]].courceId


                let trainClassId = this.$route.query.trainClassId
                let res = await this.AjaxApi.getSearch2(courceId, this.input.trim(),trainClassId)
                if(res.code === '200' && res.data){

                    let tempArr = Object.keys(res.data)
                    this.allData = []
                    tempArr.map(el=>{
                        res.data[el].map(el2=>{
                            el2.checked = false
                        })
                        this.allData.push({
                            name:el,
                            list:res.data[el]
                        })
                    })
                    this.contentData = this.allData[0].list
                    this.currentKey = this.allData[0].name
                }else{
                    this.contentData =  []
                    this.allData =  []
                }
            }
        }
    }
</script>
