import { render, staticRenderFns } from "./Words.vue?vue&type=template&id=31652038&scoped=true&slot=action&"
import script from "./Words.vue?vue&type=script&lang=js&"
export * from "./Words.vue?vue&type=script&lang=js&"
import style0 from "./Words.vue?vue&type=style&index=0&id=31652038&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31652038",
  null
  
)

export default component.exports